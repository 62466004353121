import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from "../../../components/BlogPost";
import Browser from "../../../components/BrowserDemo";
import Lesson from "../../../components/Lesson";
export const meta = {
  published: true,
  publishedAt: "2019-02-18",
  title: "Why I Write CSS in JavaScript",
  summary: "For three years, I have written all my CSS in JavaScript. In fact, I am the creator of styled-components, one of the most popular CSS-in-JS libraries. Let me explain why.",
  image: "/static/images/css-in-js.png",
  likes: 1680
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <BlogPost meta={meta}>{children}</BlogPost>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`For three years, I have styled my web apps without any `}<inlineCode parentName="p">{`.css`}</inlineCode>{` files. Instead, I have written all the CSS in JavaScript.`}</p>
    <p>{`I know what you are thinking: “why would anybody write CSS in JavaScript?!” Let me explain.`}</p>
    <h3>{`What Does CSS-in-JS Look Like?`}</h3>
    <p>{`Developers have created `}<a parentName="p" {...{
        "href": "https://github.com/michelebertoli/css-in-js"
      }}>{`different flavors of CSS-in-JS`}</a>{`. The most popular to date, with over 30,000 stars on GitHub, is a library I co-created, called `}<a parentName="p" {...{
        "href": "https://styled-components.com"
      }}>{`styled-components`}</a>{`.`}</p>
    <p>{`Using it with React looks like this:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` styled `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"styled-components"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Title`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` styled`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`h1`}</span><span parentName="code" {...{
          "className": "token styled-template-string"
        }}><span parentName="span" {...{
            "className": "token string language-css"
          }}>{`\`
  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`color`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` palevioletred`}</span>{`;`}<span parentName="span" {...{
            "className": "token string language-css"
          }}>{`
  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`font-size`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`18`}</span><span parentName="span" {...{
              "className": "token unit"
            }}>{`px`}</span></span>{`;`}<span parentName="span" {...{
            "className": "token string language-css"
          }}>{`
\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`App`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Title`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Hello`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`World`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`!`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Title`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`This renders a palevioletred `}<inlineCode parentName="p">{`<h1>`}</inlineCode>{` with a font size of 18px to the DOM:`}</p>
    <Browser html={`
  <style>
    .faEkXI {
      font-size: 18px;
      color: palevioletred;
    }
  </style>
  <h1 class="sc-ifAKCX faEkXI">Hello World!</h1>
`} mdxType="Browser" />
    <h3>{`Why I like CSS-in-JS`}</h3>
    <p>{`Primarily, `}<strong parentName="p">{`CSS-in-JS boosts my confidence`}</strong>{`. I can add, change and delete CSS without any unexpected consequences. My changes to the styling of a component will not affect anything else. If I delete a component, I delete its CSS too. No more `}<a parentName="p" {...{
        "href": "https://css-tricks.com/oh-no-stylesheet-grows-grows-grows-append-stylesheet-problem/"
      }}>{`append-only stylesheets`}</a>{`! ✨`}</p>
    <Lesson title="Confidence" body="Add, change and delete CSS without any unexpected consequences and avoid dead code." mdxType="Lesson" />
    <Lesson title="Painless Maintenance" body="Never go on a hunt for CSS affecting your components ever again." mdxType="Lesson" />
    <p>{`Teams I have been a member of are especially benefitting from this confidence boost. I cannot expect all team members, particularly juniors, to have an encyclopedic understanding of CSS. On top of that, deadlines can get in the way of quality.`}</p>
    <p>{`With CSS-in-JS, we automatically sidestep common CSS frustrations such as class name collisions and specificity wars. This keeps our codebase clean and lets us move quicker. 😍`}</p>
    <Lesson title="Enhanced Teamwork" body="Avoid common CSS frustrations to keep a neat codebase and moving quickly, regardless of experience levels." mdxType="Lesson" />
    <p>{`Regarding performance, CSS-in-JS libraries keep track of the components I use on a page and only inject their styles into the DOM. While my `}<inlineCode parentName="p">{`.js`}</inlineCode>{` bundles are slightly heavier, my users download the smallest possible CSS payload and avoid extra network requests for `}<inlineCode parentName="p">{`.css`}</inlineCode>{` files.`}</p>
    <p>{`This leads to a marginally slower time to interactive, but a much quicker first meaningful paint! 🏎💨`}</p>
    <Lesson title="Fast Performance" body="Send only the critical CSS to the user for a rapid first paint." mdxType="Lesson" />
    <p>{`I can also easily adjust the styles of my components based on different states (`}<inlineCode parentName="p">{`variant="primary"`}</inlineCode>{` vs `}<inlineCode parentName="p">{`variant="secondary"`}</inlineCode>{`) or a global theme. The component will apply the correct styles automatically when I dynamically change that context. 💅`}</p>
    <Lesson title="Dynamic Styling" body="Simply style your components with a global theme or based on different states." mdxType="Lesson" />
    <p>{`CSS-in-JS still offers all the important features of CSS preprocessors. All libraries support auto-prefixing, and JavaScript offers most other features like mixins (functions) and variables natively.`}</p>
    <hr></hr>
    <p>{`I know what you are thinking: “Max, you can also get these benefits with other tools or strict processes or extensive training. What makes CSS-in-JS special?”`}</p>
    <p>{`CSS-in-JS combines all these benefits into one handy package and enforces them. It guides me to the `}<a parentName="p" {...{
        "href": "https://blog.codinghorror.com/falling-into-the-pit-of-success/"
      }}>{`pit of success`}</a>{`: doing the right thing is easy, and doing the wrong thing is hard (or even impossible).`}</p>
    <h3>{`Who Uses CSS-in-JS?`}</h3>
    <p>{`Thousands of companies use CSS-in-JS in production, including `}<a parentName="p" {...{
        "href": "https://reddit.com"
      }}>{`Reddit`}</a>{`, `}<a parentName="p" {...{
        "href": "https://patreon.com"
      }}>{`Patreon`}</a>{`, `}<a parentName="p" {...{
        "href": "https://target.com"
      }}>{`Target`}</a>{`, `}<a parentName="p" {...{
        "href": "https://atlaskit.atlassian.com"
      }}>{`Atlassian`}</a>{`, `}<a parentName="p" {...{
        "href": "https://vogue.de"
      }}>{`Vogue`}</a>{`, `}<a parentName="p" {...{
        "href": "https://primer.style/components"
      }}>{`GitHub`}</a>{`, `}<a parentName="p" {...{
        "href": "https://pro.coinbase.com"
      }}>{`Coinbase`}</a>{`, and many more. (`}<a parentName="p" {...{
        "href": "https://github.com/mxstbr/mxstbr.com"
      }}>{`including this website`}</a>{`)`}</p>
    <h3>{`Is CSS-in-JS For You?`}</h3>
    <p>{`If you are using a JavaScript framework to build a web app with components, CSS-in-JS is probably a good fit. Especially if you are part of a team where everybody understands basic JavaScript.`}</p>
    <p>{`If you are not sure how to get started, I would recommend trying it out and seeing for yourself how good it feels! ✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      